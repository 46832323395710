import { Box, Button, CircularProgress, Typography } from "@mui/material";

import React, { useEffect } from "react";

const BriefingEnviado = () => {
  const [feedback, setFeedback] = React.useState(null);
  const [feedbackEnviado, setFeedbackEnviado] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const savedFeedback = localStorage.getItem('feedback');
    if (savedFeedback) {
      setFeedbackEnviado(true);
    }
  }, []);

  const enviaFeedback = () => {
    setLoading(true);
    const pedido = new URLSearchParams(window.location.search).get('numero_pedido');
    const body = {
      feedback: feedback,
      pedido: pedido
    }
    localStorage.setItem('feedback', feedback);
    fetch("https://hook.us1.make.com/abb5m5vz29ghlqp74oabyv2ghc2c0lpc",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setFeedbackEnviado(true);
      alert("Feedback enviado com sucesso!");
    }
    )
  }
  return (
    <Box>
      <h1 style={{textAlign: "center"}}>Briefing enviado com sucesso!</h1>
      {!feedbackEnviado && (
        <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={"20px"}>Gostaríamos muito de saber sua opinião! O que você achou do briefing? Alguma sugestão de melhoria?</Typography>
          <textarea 
          placeholder="Quais pontos você gostou no briefing? Alguma sugestão para melhorarmos?"
          style={{
            width: "90%", 
            height: "100px", 
            backgroundColor: "transparent", 
            border: "1px solid green", 
            borderRadius: "5px", 
            fontSize: "16px", 
            padding: "10px", 
            fontFamily: "Montserrat",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px"
          }}
          onChange={(e) => setFeedback(e.target.value)}
          />
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="success" onClick={enviaFeedback}>Enviar feedback</Button><Box width={'10px'}/>{loading && <CircularProgress size={20}/>}
          </Box>
        </Box>
      )}

    </Box>
  );
};

export default BriefingEnviado;