import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, Typography, TextField, Grid } from '@mui/material';

const ColorPicker = ({ register, setValue, setFormData, formField }) => {
  const [color, setColor] = useState('#a68282');
  const [cmyk, setCmyk] = useState('0, 22, 22, 35');
  const [error, setError] = useState(false);
  const [rgb, setRgb] = useState('166, 130, 130');
  const [hex, setHex] = useState('#a68282');

  const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return { r, g, b };
  };

  const rgbToHex = (r, g, b) => {
    return `#${[r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')}`;
  };

  const rgbToCmyk = (r, g, b) => {
    let c = 1 - (r / 255);
    let m = 1 - (g / 255);
    let y = 1 - (b / 255);
    const k = Math.min(c, Math.min(m, y));

    if (k === 1) {
      return '0, 0, 0, 100';
    }

    c = ((c - k) / (1 - k)) * 100;
    m = ((m - k) / (1 - k)) * 100;
    y = ((y - k) / (1 - k)) * 100;
    return `${Math.round(c)},${Math.round(m)},${Math.round(y)},${Math.round(k * 100)}`;
  };

  const cmykToRgb = (c, m, y, k) => {
    const r = 255 * (1 - c / 100) * (1 - k / 100);
    const g = 255 * (1 - m / 100) * (1 - k / 100);
    const b = 255 * (1 - y / 100) * (1 - k / 100);
    return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
  };

  const handleHexChange = (newHex) => {
    setColor(newHex);
    const rgbColor = hexToRgb(newHex);
    const newCmyk = rgbToCmyk(rgbColor.r, rgbColor.g, rgbColor.b);
    setRgb(`${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`);
    setCmyk(newCmyk);
    setHex(newHex);
    setValue(formField, newCmyk);
    setFormData(prevState => ({ ...prevState, [formField]: newCmyk }));
  };

  const handleCmykChange = (newCmyk) => {
    const regex = /^([0-9]{0,3}),?\s*([0-9]{0,3}),?\s*([0-9]{0,3}),?\s*([0-9]{0,3})$/;
  
    // Verifica se o formato básico está correto (valores numéricos separados por vírgula)
    if (regex.test(newCmyk)) {
      let valoresCmyk = newCmyk.split(',').map(valor => {
        valor = valor.trim();
        // Permite que o valor fique vazio enquanto o usuário está editando
        if (valor === '') return valor;
        // Limita o valor entre 0 e 100
        if (parseInt(valor) > 100) return '100';
        if (parseInt(valor) < 0) return '0';
        return valor;
      });
  
      setError(false); // Remove o erro, se houver
      setCmyk(valoresCmyk.join(','));
  
      // Apenas atualiza as outras cores se todos os valores forem válidos (não vazios)
      if (valoresCmyk.every(valor => valor !== '')) {
        const [c, m, y, k] = valoresCmyk.map(Number);
        const rgbColor = cmykToRgb(c, m, y, k);
        const newHex = rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b);
        setRgb(`${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`);
        setColor(newHex);
        setHex(newHex);
        setValue(formField, valoresCmyk.join(','));
        setFormData(prevState => ({ ...prevState, [formField]: valoresCmyk.join(',') }));
      }
    } else {
      setError(true); // Mostra erro se o formato estiver incorreto
    }
  };


  const handleKeyDown = (e) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'Tab',
      'ArrowLeft', 'ArrowRight',
      'ArrowUp', 'ArrowDown', ','
    ];

    // Impede digitação de letras, mais de 3 vírgulas ou caracteres não numéricos
    if ((!allowedKeys.includes(e.key) && (e.key < '0' || e.key > '9')) || (e.target.value.split(',').length > 3 && e.key === ',')) {
      e.preventDefault();
    }
  };

  return (
    <Box mt={4} display="flex" flexDirection="column" alignItems="center" textAlign="center">
      <h2>Escolha a cor da sua embalagem:</h2>
      <Grid container spacing={2} alignItems="center" padding="10px">
        <Grid item xs={12} sm={6} md={12}>
          <HexColorPicker
            color={color}
            onChange={handleHexChange}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: color, width: '100%', height: '100px', borderRadius: '8px', border: '1px solid #ccc' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="subtitle1">Valor CMYK:</Typography>
          <TextField
            value={cmyk}
            onChange={(e) => handleCmykChange(e.target.value)}
            variant="outlined"
            fullWidth
            onKeyDown={handleKeyDown}
            error={error}
            helperText={error? "Insira os valores no padrão (0, 22, 22, 35)." :"Insira os valores CMYK separados por vírgula (ex: 0, 22, 22, 35)"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ColorPicker;
